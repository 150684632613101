var getDefaultTranslation;

getDefaultTranslation = require('../../shared/translations.js').getDefaultTranslation;

(function(W) {
  var initializeEveryTime, initializeOnce;
  initializeOnce = W.initializeOnce = function() {
    var ref;
    W._initializers.initDetect();
    W._initializers.initWLog();
    W._initializers.initVisitorKey();
    if ((ref = W._initializers) != null ? ref.initEventLoop : void 0) {
      W._initializers.initEventLoop();
    }
    W._initializers.initFullscreenTriggers();
    W.trigger('ev1initonce');
    return performance.mark('player_init_start');
  };
  initializeEveryTime = W.initializeEveryTime = function() {
    var ref, ref1, ref2, ref3;
    W.trigger('ev1initstart');
    if ((ref = W._initializers) != null ? ref.initEmbedShepherd : void 0) {
      W._initializers.initEmbedShepherd();
    }
    if ((ref1 = W._initializers) != null ? ref1.initMux : void 0) {
      W._initializers.initMux();
    }
    if ((ref2 = W._initializers) != null ? ref2.initAsyncEmbeds : void 0) {
      W._initializers.initAsyncEmbeds();
    }
    W._initializers.initSpecificTime();
    getDefaultTranslation();
    if ((ref3 = W.eventLoop) != null) {
      ref3.resync();
    }
    return W.trigger('ev1initend');
  };
  W.destroy = function() {
    W.trigger('ev1destroystart');
    W._destructors.destroyAsyncEmbeds();
    W._destructors.destroyEmbedShepherd();
    W._destructors.destroyEmbedLinks();
    W._destructors.destroyFullscreenTriggers();
    W._destructors.destroyEventLoop();
    W._destructors.destroyVisitorKey();
    W._destructors.destroyWLog();
    W._destructors.destroyMux();
    return W.trigger('ev1destroyend');
  };
  W.destroyGlobals = function() {
    var k, results, v;
    window.Wistia = null;
    window.wistiaApi = null;
    window.wistiaDispatch = null;
    window.wistiaBindIframes = null;
    window.wistiaEmbeds = null;
    window._wistiaElemId = null;
    window.wistiaInitQueue = null;
    window.wistiaInit = null;
    window.wistiaEmbedShepherdReady = null;
    results = [];
    for (k in window) {
      v = window[k];
      if (/^wistiajson/.test(k)) {
        results.push(window[k] = null);
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
  W.reinitialize = function() {
    W.destroy();
    W.initializeOnce();
    return W.initializeEveryTime();
  };
  if (!W._initialized) {
    W._initialized = true;
    W.initializeOnce();
  }
  return W.initializeEveryTime();
})(Wistia);
