import W from 'wistia_namespace.js';
import { makeWbindable } from 'utilities/wbindable.js';

makeWbindable(W);

export const globalBind = W.bind.bind(W);
export const globalOn = W.on.bind(W);
export const globalOff = W.off.bind(W);
export const globalRebind = W.rebind.bind(W);
export const globalTrigger = W.trigger.bind(W);
export const globalUnbind = W.unbind.bind(W);
