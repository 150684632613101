import { filter, READY } from 'utilities/assets.js';

const DEFAULT_QUALITY_MIN = 360;
const DEFAULT_QUALITY_MAX = 2160;

const assetSizeShorthandToNumeric = (size) => {
  if (size === '4k') {
    return 2160;
  }
  // Strings like 1080p will be parsed as 1080
  return parseInt(size, 10);
};

export const parseLegacyVideoQualityWidth = (videoQuality, assets) => {
  const asset = filter(assets, {
    container: /mp4/,
    status: READY,
    public: true,
  })[0];

  if (/width:/.test(videoQuality) && asset) {
    const [_, val] = videoQuality.match(/width:(\d+)/i);
    const width = parseInt(val, 10);
    const aspect = asset.width / asset.height;
    const height = Math.round(width / aspect);
    return height;
  }
  return undefined;
};

export const inferQualityRangeFromOpts = (opts) => {
  if (opts.videoQuality && opts.videoQuality !== 'auto') {
    // Legacy videoQuality option overrides qualityMin and qualityMax
    if (opts.videoQuality === 'sd-only') {
      return [200, 360];
    }
    if (opts.videoQuality === 'md') {
      return [540, DEFAULT_QUALITY_MAX];
    }
    if (opts.videoQuality === 'hd-only') {
      return [720, DEFAULT_QUALITY_MAX];
    }
    return [DEFAULT_QUALITY_MIN, DEFAULT_QUALITY_MAX];
  }
  let qualityMin;
  let qualityMax;
  if (opts.qualityMin) {
    qualityMin = assetSizeShorthandToNumeric(opts.qualityMin);
  } else {
    qualityMin = DEFAULT_QUALITY_MIN;
  }
  if (opts.qualityMax) {
    qualityMax = assetSizeShorthandToNumeric(opts.qualityMax);
  } else {
    qualityMax = DEFAULT_QUALITY_MAX;
  }
  return [qualityMin, qualityMax];
};
