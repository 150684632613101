import { eV1Protocol, eV1HostWithPort } from './hosts.js';

export type DynamicImportOptions = {
  host?: string;
};
export const dynamicImport = async (
  filePath: string,
  options: DynamicImportOptions = {},
): Promise<unknown> => {
  const host: string = options.host ?? eV1HostWithPort();

  return import(/* webpackIgnore: true */ `${eV1Protocol()}//${host}/${filePath}`);
};
