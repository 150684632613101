import W from 'wistia_namespace.js';
import { pageLoaded } from 'utilities/elem.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';

const hasAnyIntegrations = (video) => {
  if (video._mediaData && video._mediaData.integrations) {
    for (let k in video._mediaData.integrations) {
      if (video._mediaData.integrations[k]) {
        return true;
      }
    }
  }
  return false;
};

window._wq = window._wq || [];
window._wq.push({
  id: '_all',
  onHasData: (video) => {
    if (hasAnyIntegrations(video)) {
      pageLoaded(() => {
        setTimeout(() => {
          dynamicImport('assets/external/allIntegrations.js').then((mod) => {
            mod.runIntegrationsSetup();
          });
        }, 1000);
      });
    }
  },
});
