/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { globalEventLoop } from 'utilities/event_loop.js';

(function (W) {
  if (W._initializers != null ? W._initializers.initEventLoop : undefined) {
    return;
  }

  W._initializers.initEventLoop = () =>
    // This will set W.eventLoop if it's not set already.
    (W.eventLoop = globalEventLoop);

  return (W._destructors.destroyEventLoop = function () {
    if (W.eventLoop != null) {
      W.eventLoop.stop();
    }
    return (W.eventLoop = null);
  });
})(window.Wistia);
