import root from 'utilities/root.js';
import Wistia from 'wistia_namespace.js';
import { merge } from 'utilities/obj.js';
import { wlog } from 'utilities/wlog.js';
import { removeScriptsBySrc } from 'utilities/script-utils.js';
import { cacheMediaData, uncacheMediaData } from 'utilities/media-data-cache.js';
import {
  eV1Protocol,
  cdnFastWistiaComHost,
  cdnFastWistiaNetHost,
  forceValidFastWistiaHost,
  mediaDataHost,
} from 'utilities/hosts.js';
import { proto as urlProto } from 'utilities/url.js';
import { setOrGet as wlocalStorage } from 'utilities/legacyLocalstorage.js';
import { send } from 'utilities/metrics.js';
import { mediaDataTransforms } from 'utilities/media-data-transforms.js';
import { countMetric } from 'utilities/simpleMetrics.js';

if (Wistia._remoteData == null) {
  Wistia._remoteData = {};
}

export const fetchFreshMediaDataJson = (hashedId, options) => {
  const url = mediaDataUrl(hashedId, options);
  return fetch(url)
    .then((response) => response.json())
    .catch((e) => {
      wlog.error('error fetching mediaData', e);
    });
};

/**
 *
 * @param {string} hashedId hashedId string of the media or ab-test
 * @param {Object} options options object - can be `password`, `idType`, or any
 * embed options that will be merged into the mediaData.embedOptions
 * @returns {Promise} Returns a promise containing the `mediaData` object
 */
export const fetchMedia = (hashedId, options = {}) => {
  return new Promise((resolve, reject) => {
    const cachedData = dataFromCache(hashedId, options);
    if (cachedData) {
      wlog.info('fetchMedia', hashedId, 'from local cache', cachedData);
      let chosenMedia = cachedData;

      // pick a media if the cachedData is an ab-test
      if (cachedData.type && cachedData.type === 'ab-test') {
        wlog.info('fetch', hashedId, 'local cache-ab-test', cachedData);
        chosenMedia = abTestMediaSelector(cachedData, options);
      }

      resolve(cachedData);
    } else {
      wlog.info('fetchMedia', hashedId, 'fetching');
      const url = mediaDataUrl(hashedId, options);
      const newUrl = new window.URL(mediaDataUrl(hashedId, options));
      if (options.password != null) {
        newUrl.searchParams.append('password', options.password);
      }

      if (options.idType) {
        newUrl.searchParams.append('idType', options.idType);
      }

      fetch(newUrl)
        .then((response) => response.json())
        .then((mediaWithOpts) => {
          let mediaData = mediaWithOpts;
          if (mediaWithOpts.error) {
            wlog.info('fetch', hashedId, 'error', mediaWithOpts);
            cacheMedia(hashedId, mediaWithOpts);
          } else if (mediaWithOpts.type && mediaWithOpts.type === 'ab-test') {
            wlog.info('fetch', hashedId, 'responded-ab-test', mediaWithOpts);
            mediaData = abTestMediaSelector(mediaWithOpts, options);
          } else {
            mediaData = transformResponse(mediaWithOpts, options);
            cacheMedia(hashedId, mediaWithOpts.media);
          }

          resolve(mediaData);
        })
        .catch((error) => {
          wlog.error(`error out fetching ${url}`);
          // This will be an aggregate of any player loading failures
          countMetric('player/failure/fetch-media-failed');

          reject(error);
        });
    }
  });
};

export const transformResponse = (mediaData, options) => {
  const mediaWithOpts = { ...mediaData };
  const transformOpts = merge({}, mediaWithOpts.media?.embedOptions, options);

  if (mediaWithOpts.error) {
    return mediaWithOpts;
  }

  delete mediaWithOpts.media.unnamed_assets;
  mediaDataTransforms(mediaWithOpts.media, transformOpts);
  return mediaWithOpts.media;
};

export const mediaDataUrl = (hashedId, options = {}) => {
  const host = mediaDataHost(options);
  return `${eV1Protocol()}//${host}/embed/medias/${hashedId}.json`;
};

export const mediaDataScriptRegExp = (hashedId) => {
  const protocolMatch = location.protocol === 'https:' ? 'https' : 'https?';
  return new RegExp(
    `^(${protocolMatch}:)?//((${cdnFastWistiaComHost().replace(
      '.',
      '\\.',
    )})|(${cdnFastWistiaNetHost().replace('.', '\\.')}))/embed/medias/${hashedId}\\.jsonp\\??`,
  );
};

export const cacheMedia = (hashedId, data) => {
  return cacheMediaData(hashedId, data);
};

export const cacheAbTest = (hashedId, data) => {
  return (Wistia._remoteData[`ab_test_${hashedId}`] = data);
};

export const uncacheMedia = (hashedId) => {
  uncacheMediaData(hashedId);
  removeSpeedDemonScriptAndData(hashedId);
};

const removeSpeedDemonScriptAndData = (hashedId) => {
  window[`wistiajsonp-/embed/medias/${hashedId}.json`] = null;
  removeScriptsBySrc(mediaDataUrl(hashedId), {
    scriptRegex: mediaDataScriptRegExp(hashedId),
  });
};

export const mediaFromCache = (hashedId) => {
  return dataFromCache(hashedId, { idType: 'media' });
};

export const abTestFromCache = (hashedId) => {
  return dataFromCache(hashedId, { idType: 'ab-test' });
};

const dataFromCache = (hashedId, options = {}) => {
  // default to getting a media from the cache
  const type = options.idType || 'media';

  const resultFromFetchCache = Wistia._remoteData[`${type}_${hashedId}`];
  if (resultFromFetchCache) {
    return resultFromFetchCache;
  }

  const speedDemonData = root[`wistiajsonp-/embed/medias/${hashedId}.json`];
  if (speedDemonData != null && speedDemonData.media) {
    return speedDemonData.media;
  }

  return null;
};

// given an ab_test response data, we choose a media to initialize
// 1. if there is a winner from the ab_test, we always choose that media
// 2. if there is a saved hashedId in local storage, we will use that next
// 3. if neither, we will choose one at random to display, and sve the chosen one
// in local storage for subsequent views
export const abTestMediaSelector = (responseData, options) => {
  if (responseData.error) {
    return responseData;
  }

  // warn if one (or both) of the medias is not present
  if (responseData.missingVideos) {
    console.warn(
      'The following videos were not present in the AbTest: ',
      responseData.missingVideos,
    );
  }

  cacheAbTest(responseData.hashedId, responseData);

  // filter out control or variants that were not included
  const eligibleMediaDatas = [responseData.control, responseData.variant].filter(Boolean);
  eligibleMediaDatas.forEach((m) => {
    const transformOpts = merge({}, m.media?.embedOptions, options);
    mediaDataTransforms(m.media, transformOpts);
    cacheMedia(m.media.hashedId, m.media);
  });

  const localStorageKey = `ab_test.${responseData.hashedId}.selected_media_hashed_id`;

  let savedHashedId;
  if (responseData.winnerHashedId) {
    savedHashedId = responseData.winnerHashedId;
  } else {
    savedHashedId = wlocalStorage(localStorageKey);
  }

  let selectedMedia;

  // determine a random number
  let coinFlipResult;
  let cryptoRandom;
  let mathRandom;
  if (typeof crypto !== 'undefined') {
    cryptoRandom = crypto.getRandomValues(new Uint8Array(1));
    coinFlipResult = cryptoRandom[0] & 1;
  } else {
    mathRandom = Math.random();
    coinFlipResult = Number(mathRandom > 0.5);
  }

  // if we get any amount of media datas back other than 2, just
  // pick the control
  const index = eligibleMediaDatas.length === 2 ? coinFlipResult : 0;
  selectedMedia = eligibleMediaDatas[index].media;

  // override with a saved media if there is one
  let savedMedia = false;
  eligibleMediaDatas.some((m) => {
    if (m.media.hashedId === savedHashedId) {
      selectedMedia = m.media;
      savedMedia = true;
    }

    return m.media.hashedId === savedHashedId;
  });

  const trackingData = {
    numberOfMedias: eligibleMediaDatas.length,
    coinFlip: coinFlipResult,
    savedMedia,
    agent: navigator.userAgent || null,
    selectedHashedId: selectedMedia.hashedId,
    stringCryptoRandom: String(cryptoRandom),
  };

  if (cryptoRandom != null) {
    trackingData.cryptoRandom = cryptoRandom[0];
  } else {
    trackingData.mathRandom = mathRandom;
  }

  send('count', 'player/ab-test-selection', 1, trackingData);

  wlocalStorage(localStorageKey, selectedMedia.hashedId);
  return selectedMedia;
};
