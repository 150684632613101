import { StopGo } from '../../../../utilities/stopgo.js';
import type { WistiaPlayer } from '../../../../external/WistiaPlayer.ts';

type StopGoType = {
  (x?: boolean): void;
  synchronize: (y: (z: () => void) => void) => void;
  new (): StopGoType;
};

export class TranslationApi {
  #commandQueueOpen: StopGoType;

  #wistiaPlayerComponent: WistiaPlayer;

  /**
   * @param wpComponent instance of the wistia-player component
   */
  public constructor(wpComponent: WistiaPlayer) {
    this.#wistiaPlayerComponent = wpComponent;

    const SG = StopGo as StopGoType;
    this.#commandQueueOpen = new SG();

    this.#commandQueueOpen(true);
  }

  /**
   * bind doesn't and won't exist on wistia-player, so we need to call its PublicApi instance directly
   * @returns {Function} returns an unbind function
   */
  public bind(event: string, callback: () => void): (() => void) | undefined {
    return this.#wistiaPlayerComponent.api?.bind(event, callback);
  }

  /**
   * Maps mediaId property to the old hashedId method
   * @returns {string} returns the hashed id of the video
   */
  public hashedId(): string {
    return this.#wistiaPlayerComponent.mediaId;
  }

  /**
   * turns the promise version from the wistia-player into the old chainable version
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public pause(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .pause()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if pause failed
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * turns the promise version from the wistia-player into the old chainable version
   * @returns {this} returns an instance of the TranslationApi for chaining
   */
  public play(): this {
    this.#commandQueueOpen.synchronize((done) => {
      this.#wistiaPlayerComponent
        .play()
        .then(() => {
          done();
        })
        // old api always continued with the synchronize, even if play failed
        .catch(() => {
          done();
        });
    });

    return this;
  }

  /**
   * Maps state property to the old state method
   * @returns {string} returns the state of the video
   */
  public state(): string {
    return this.#wistiaPlayerComponent.state;
  }

  /**
   * Maps currentTime property to the old time method
   * @param {number | undefined} newTime - the new time to set the video to
   * @returns {number} returns the current time of the video
   */
  public time(newTime: number | undefined): number | this {
    // Get
    if (newTime === undefined) {
      return this.#wistiaPlayerComponent.currentTime;
    }

    // Set
    this.#commandQueueOpen.synchronize((done) => {
      const seekComplete = () => {
        this.#wistiaPlayerComponent.api?.unbind('seeked', seekComplete);
        done();
      };
      this.#wistiaPlayerComponent.api?.bind('seeked', seekComplete);
      this.#wistiaPlayerComponent.currentTime = newTime;
    });

    return this;
  }
}
