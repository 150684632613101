// embed.js is the "Wistia Library." It has all the features we use to
// embed a video.

import '../embeds/media/modules/_all_modules.js';

import '../embeds/media/modules/_init.coffee';
import './vulcanV2Player.js';

import './WistiaPlayer.ts';

window.Wistia.wistia = Date.now();
