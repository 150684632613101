/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import * as Hosts from 'utilities/hosts.js';
import * as MediaData from 'utilities/media_data.js';
import * as PlaylistData from 'utilities/playlist_data.js';
import { appHostname } from '../../../appHostname.js';

(function (W) {
  if (W.remote) {
    return;
  }

  W.remote = {};
  W.remote.appHostname = appHostname;
  W.remote.embedHost = Hosts.cdnFastWistiaComHost;
  W.remote.externalEmbedHost = Hosts.cdnFastWistiaNetHost;
  W.remote.appHost = Hosts.appHost;
  W.remote.assetHost = Hosts.deliveryHost;

  // TODO: can be deleted once all outside references are detected and deleted.
  W.remote.media = MediaData.fetchMedia;
  W.cacheMedia = MediaData.cacheMedia;
  W.cacheAbTest = MediaData.cacheAbTest;
  W.uncacheMedia = MediaData.uncacheMedia;
  W.mediaFromCache = MediaData.mediaFromCache;
  W.abTestFromCache = MediaData.abTestFromCache;
  W.remote.playlist = PlaylistData.fetchPlaylist;
})(window.Wistia);
