import W from 'wistia_namespace.js';
import { globalTrigger } from 'utilities/globalBindAndTrigger.js';
import { getWistiaLocalStorage, updateWistiaLocalStorage } from 'utilities/wistiaLocalStorage.js';

export const migrateLegacyVisitorTracking = () => {
  const legacyIsEnabled = getWistiaLocalStorage().visitorTrackingEnabled;
  if (legacyIsEnabled != null) {
    // Migrate old structure to new structure
    //
    // old:
    // localStorage
    //   visitorTrackingEnabled: boolean
    //
    // new:
    // localStorage
    //   visitorTracking: {
    //     isEnabled: boolean,
    //     updatedAt: timestamp,
    //   }
    //
    updateWistiaLocalStorage((ls) => delete ls.visitorTrackingEnabled);
    W._visitorTracking = {};
    W._visitorTracking[W._visitorTrackingDomain] = {
      isEnabled: legacyIsEnabled,
      updatedAt: Date.now(),
    };
    updateWistiaLocalStorage((ls) => (ls.visitorTracking = W._visitorTracking));
  }
};

if (!W._visitorTrackingDomain) {
  W._visitorTrackingDomain = location.hostname || '';
}

// Initialize from localStorage, or with an empty hash if not set.
if (!W._visitorTracking) {
  migrateLegacyVisitorTracking();
  W._visitorTracking = getWistiaLocalStorage().visitorTracking || {};
}

export const consent = (val) => {
  if (val == null) {
    return isVisitorTrackingEnabled();
  }
  return setVisitorTrackingEnabled(val);
};
W.consent = consent;

export const setVisitorTrackingEnabled = (val, domain = W._visitorTrackingDomain) => {
  if (val === 'default') {
    delete W._visitorTracking[domain];
  } else {
    W._visitorTracking[domain] = {
      isEnabled: `${val}` === 'true',
      updatedAt: Date.now(),
    };
  }
  updateWistiaLocalStorage((obj) => (obj.visitorTracking = W._visitorTracking));
  globalTrigger('visitortrackingchange', val);
};

const isCurrentDomainOrAnyParentDomainsEnabled = () => {
  if (W._visitorTrackingDomain) {
    const domainParts = W._visitorTrackingDomain.split('.');
    while (domainParts.length > 0) {
      const entry = W._visitorTracking[domainParts.join('.')];
      const enabledVal = entry && entry.isEnabled;
      if (enabledVal != null) {
        return enabledVal;
      }
      domainParts.shift();
    }
  }

  // returns undefined instead of false as a result so that we can have
  // different behavior if it has not been set.
};

export const isVisitorTrackingEnabled = () => {
  if (typeof W._visitorTracking === 'boolean') {
    // Legacy. We previously persisted this data as a boolean
    return W._visitorTracking;
  }

  if (W._visitorTracking) {
    // _visitorTracking has been set. Let's check the value for the current
    // domain. And if that's set, we return.
    const isEnabledVal = isCurrentDomainOrAnyParentDomainsEnabled();
    if (isEnabledVal != null) {
      return Boolean(isEnabledVal);
    }
  }

  const apis = [];
  if (W.api && W.api.all) {
    try {
      apis.push(...W.api.all());
    } catch (e) {
      // If W.api.all() is called before W._data is setup, this will throw
      // an exception. That's okay--it just means there are effectively no
      // video embeds yet.
    }
  }

  if (W.channel && W.channel.all) {
    try {
      apis.push(...W.channel.all());
    } catch (e) {
      // If W.api.all() is called before W._data is setup, this will throw
      // an exception. That's okay--it just means there are effectively no
      // channel embeds yet.
    }
  }

  // By default, if any videos have privacy mode enabled, then we disable
  // visitor tracking for all videos on the page. In practice, we would only
  // see this occur if videos from multiple accounts--with different privacy
  // mode settings--appear on the same page.
  const isPrivacyModeEnabled = apis.some((api) => {
    const data = api._mediaData || api._galleryData || {};
    return data.privacyMode === true;
  });

  return !isPrivacyModeEnabled;
};
