import W from 'wistia_namespace.js';
import { cachedDetect } from 'utilities/detect.js';
import { globalOn, globalTrigger } from 'utilities/globalBindAndTrigger.js';
import { doTimeout } from 'utilities/timeout-utils.js';
import { seqId } from 'utilities/seqid.js';
import {
  addInlineCss,
  elemBind,
  elemCancelFullscreen,
  elemHeight,
  elemRebind,
  elemRemove,
  elemRequestFullscreen,
  elemStyle,
  elemUnbind,
  elemWidth,
  fullscreenElement,
  inUserEventContext,
} from 'utilities/elem.js';
import { scrollLeft } from '../../../../../utilities/scroll.js';
import { PlayerBehavior } from './PlayerBehavior.js';

const detect = cachedDetect();

class FullscreenBehavior extends PlayerBehavior {
  init() {
    const impl = this.impl;

    this.unbinds.push(
      globalOn('enterfullscreen', this.onEnterFullscreen),
      globalOn('cancelfullscreen', this.onCancelFullscreen),
      elemBind(window, 'resize', () => {
        this.fullscreenResizeToWindowFlurry();
      }),
      impl.on('playpending', this.maybeAutoFullscreen),
    );

    // there's no engine available until whenVideoElementInDom resolves.
    impl.whenVideoElementInDom().then(() => {
      impl.engine.bind('webkitbeginfullscreen', this.onEnterWebkitFullscreen);
      impl.engine.bind('webkitendfullscreen', this.onCancelFullscreen);
    });

    // If the video is playing and the user turns their phone to landscape,
    // let's fullscreen the video. When they turn back to portrait,
    // unfullscreen it. If the option for it isn't present, default to true
    if (this.getIsFullscreenOnRotateToLandscape()) {
      if (window.screen.orientation) {
        // For Chrome on Android
        this.unbinds.push(elemBind(window.screen.orientation, 'change', this.onOrientationChange));
      } else {
        // For Safari on iOS
        this.unbinds.push(elemBind(window, 'orientationchange', this.onOrientationChange));
      }
    }
  }

  onEnterFullscreen = (elem) => {
    const impl = this.impl;
    if (elem === impl.chrome || elem.tagName === 'WISTIA-PLAYER') {
      // When using fullscreen API
      impl.info('_onEnterFullscreen');
      impl.trigger('beforeenterfullscreen');
      this._hasBeenInFullscreen = true;
      this._inFullscreen = true;
      impl.notFullscreen(false);
      this.injectFullscreenStyles();
      impl.behaviors.embed.updateBackgroundColor();
      impl.width(elemWidth(window), { fullscreen: true, dontChangeContainer: true });
      impl.height(elemHeight(window), { fullscreen: true, dontChangeContainer: true });
      if (impl.engine) {
        impl.engine.onEnterFullscreen();
      }

      impl.trigger('enterfullscreen');
      impl.trigger('enter-fullscreen'); // legacy
    }
  };

  onEnterWebkitFullscreen = (_e) => {
    // When fullscreening <video> directly (iOS iframes)
    const impl = this.impl;
    this._hasBeenInFullscreen = true;
    this._inFullscreen = true;
    this._nativeFullscreen = true;
    impl.notFullscreen(false);
    impl.trigger('enterfullscreen');
    impl.trigger('enter-fullscreen'); // legacy
  };

  onCancelFullscreen = () => {
    const impl = this.impl;

    if (this._nativeFullscreen) {
      this._nativeFullscreen = false;
      this._inFullscreen = false;
      impl.notFullscreen(true);
      impl.trigger('cancelfullscreen');
      impl.trigger('cancel-fullscreen'); // legacy
    } else if (this._inFullscreen) {
      impl.info('onCancelFullscreen');
      this._inFullscreen = false;
      impl.notFullscreen(true);
      this.removeFullscreenStyles();
      impl.behaviors.embed.updateBackgroundColor();
      impl.width(this._widthBeforeFullscreen);
      impl.height(this._heightBeforeFullscreen);
      impl.fit();
      if (impl.engine) {
        impl.engine.onLeaveFullscreen();
      }
      impl.trigger('cancelfullscreen');
      impl.trigger('cancel-fullscreen'); // legacy
    }
  };

  onOrientationChange = () => {
    if (!this.getIsFullscreenOnRotateToLandscape()) {
      return;
    }

    const impl = this.impl;
    this.fullscreenResizeToWindowFlurry();
    if (window.orientation === -90 || window.orientation === 90) {
      if (impl.state() !== 'playing' || impl.engine.isMuted()) {
        return;
      }
      impl.requestFullscreen();
    } else {
      impl.cancelFullscreen();
    }
  };

  fullscreenResizeToWindowFlurry = () => {
    const impl = this.impl;
    this.fullscreenResizeToWindow();
    [0, 50, 100, 200, 300, 500, 1000].forEach((timeout) => {
      doTimeout(`${impl.uuid}.fs-resize-flurry-${timeout}`, this.fullscreenResizeToWindow, timeout);
    });
  };

  fullscreenResizeToWindow = () => {
    const impl = this.impl;
    if (impl.inFullscreen() && !this._nativeFullscreen) {
      impl.debug('fullscreenResizeToWindow');

      // We only want to change the dimensions of the chrome, when in
      // fullscreen mode, not the embed container itself. Otherwise, if the
      // width of the page is dependent on the width of the embed container,
      // we'll cause an infinite resizing loop.
      const currentWidth = impl.width();
      const currentHeight = impl.height();
      const winWidth = elemWidth(window);
      const winHeight = elemHeight(window);
      if (currentWidth !== winWidth) {
        impl.width(winWidth, { fullscreen: true, dontChangeContainer: true });
      }
      if (currentHeight !== winHeight) {
        impl.height(winHeight, { fullscreen: true, dontChangeContainer: true });
      }
    }
  };

  // There are some CSS conflicts that just _cannot_ be dealt with without a
  // clever reset. This style lives only in fullscreen mode to facilitate
  // that.
  injectFullscreenStyles() {
    if (this._fullscreenStyle) {
      return;
    }

    if (!document.body.id) {
      this._docBodyId = seqId('wistia_', '_tmp_body_id');
      document.body.setAttribute('id', this._docBodyId);
    }

    let css = `
      #${document.body.id}, #${document.body.id} :full-screen-ancestor {
        animation-name: none;
        -webkit-animation-name: none;
        transform: none;
        -webkit-transform: none;
      }
    `;

    const prefixes = ['webkit', 'moz', 'ms', 'o'];
    prefixes.forEach((vendor) => {
      css += `
      #${document.body.id} :-${vendor}-full-screen-ancestor {
        animation-name: none;
        -webkit-animation-name: none;
        transform: none;
        -webkit-transform: none;
      }
      \n
      `;
    });

    this._fullscreenStyle = addInlineCss(this.impl.wrapperElem, css);
  }

  removeFullscreenStyles() {
    if (!this._fullscreenStyle) {
      return;
    }

    if (this._docBodyId && document.body.id === this._docBodyId) {
      document.body.removeAttribute('id');
      this._docBodyId = null;
    }

    elemRemove(this._fullscreenStyle);
    this._fullscreenStyle = null;
  }

  requestFullscreen() {
    if (
      this.impl._opts._inIframe &&
      /two_stroke/i.test(this.impl.bestEngine()) &&
      (detect.iphone || detect.ipad)
    ) {
      return;
    }
    const impl = this.impl;

    if (impl.inFullscreen()) {
      return;
    }
    impl.info('requestFullscreen');
    this._widthBeforeFullscreen = impl.width();
    this._heightBeforeFullscreen = impl.height();

    // for the first time fullscreen is requested on videos videos whose
    // width or height does not change after init,
    // the center elem that gets measured will not have an explicit width set, but
    // it will get an inherited width: 100% !important applied from the user agent.
    // We want to explicitiy set a width value here of the current width
    impl.width(impl.width());

    if (impl._inNativeMode()) {
      impl.engine?.requestFullscreen();
    } else if (detect.fullscreenEnabled) {
      elemRequestFullscreen(impl.chrome);
    } else if (this.getAllowFakeFullscreen()) {
      // We're likely on iOS and don't have a proper fullscreen API, so
      // we can either do a fake full screen or full screen the video element
      // and get the native quicktime controls.
      this.enterFakeFullscreen();
    } else if (impl.engine) {
      if (impl.state() === 'beforeplay' && (detect.iphone || detect.ipad)) {
        // without this, if the video is fullscreened before play, it takes a few
        // clicks to actually work.
        impl.engine.play().then(() => impl.engine.pause());
      }

      impl.engine?.requestFullscreen();
    }
  }

  cancelFullscreen() {
    const impl = this.impl;

    if (!impl.inFullscreen()) {
      return;
    }
    impl.info('cancelFullscreen');
    if (impl._inNativeMode()) {
      impl.engine.cancelFullscreen();
    } else if (detect.fullscreenEnabled) {
      elemCancelFullscreen();
    } else if (this.getAllowFakeFullscreen()) {
      this.cancelFakeFullscreen();
    } else {
      impl.engine.cancelFullscreen();
    }
  }

  // On iOS there's no proper fullscreen API so we normally just fullscreen
  // the video and use the native quicktime controls, but sometimes we want
  // to do this hack to attempt fullscreen vulcan -- like in the case where
  // we're playing back multiple streams at once
  getAllowFakeFullscreen() {
    // Only allow this on iOS10+ where playsinline works
    return (
      !this.impl._opts._inIframe &&
      detect.ios.version >= 10 &&
      (this.impl._opts.fakeFullscreen || /two_stroke/i.test(this.impl.bestEngine()))
    );
  }

  // defaults to true, unless this is a spherical video because we don't want
  // to take the viewer out of fullscreen as they wave their phone all around
  getIsFullscreenOnRotateToLandscape() {
    if (!detect.touchScreen) {
      return false;
    }

    // Never do this on iOS if it's before version 10 because playsinline is not supported
    if (detect.ios.version > 0 && detect.ios.version < 10) {
      return false;
    }

    const fullscreenOnRotateToLandscapeOpt = this.impl._attrs.fullscreenOnRotateToLandscape;

    if (fullscreenOnRotateToLandscapeOpt != null) {
      return fullscreenOnRotateToLandscapeOpt;
    }

    return true;
  }

  enterFakeFullscreen() {
    const impl = this.impl;
    this._widthBeforeFullscreen = impl.width();
    this._heightBeforeFullscreen = impl.height();

    this._inFakeFullscreen = true;
    this._chromeParent = impl.chrome.parentNode;
    document.body.appendChild(impl.chrome);

    this._leftOffsetBeforeFullscreen = scrollLeft();

    this.onEnterFullscreen(impl.chrome);
    this.setupFakeFullscreenBindings();

    elemStyle(impl.chrome, {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 2147483647, // max 32bit int
    });
    scrollLeft(0);
    if (impl.engine) {
      impl.engine.onEnterFullscreen();
    }
  }

  setupFakeFullscreenBindings() {
    const impl = this.impl;
    // iOS Safari's minimal UI is a huge nuissance. In portrait, our controls
    // will be at the very bottom of the screen, but as soon as you tap there
    // it brings up Safari's bottom menu bar instead and does not issue any
    // events as far as I can tell so we must poll. Why there isn't a proper
    // resize event here is beyond me.
    W.eventLoop.add(`${impl.uuid}.fakefullscreen`, 100, () => {
      this.fullscreenResizeToWindow();
    });
    if (!this._preventDefaultForTouchMove) {
      this._preventDefaultForTouchMove = function (event) {
        event.preventDefault();
      };
    }
    elemRebind(impl.uiContainer, 'touchmove', this._preventDefaultForTouchMove);
  }

  cancelFakeFullscreen() {
    const impl = this.impl;

    this._inFakeFullscreen = false;
    this.onCancelFullscreen(impl.chrome);
    if (this._chromeParent) {
      this._chromeParent.appendChild(impl.chrome);
    }
    impl.chrome.style.position = '';
    scrollLeft(this._leftOffsetBeforeFullscreen);

    W.eventLoop.remove(`${impl.uuid}.fakefullscreen`);

    if (impl.engine) {
      impl.engine.onLeaveFullscreen();
    }

    if (this._preventDefaultForTouchMove != null) {
      elemUnbind(impl.uiContainer, 'touchmove', this._preventDefaultForTouchMove);
    }
  }

  transferStateFrom(otherImpl) {
    const otherBehavior = otherImpl.behaviors && otherImpl.behaviors.fullscreen;
    if (!otherBehavior) {
      return;
    }

    for (let k in otherBehavior) {
      if (Object.hasOwn(otherBehavior, k)) {
        const v = otherBehavior[k];
        if (k[0] === '_' && typeof v !== 'function') {
          this[k] = v;
        }
      }

      if (otherBehavior._inFullscreen) {
        const impl = this.impl;
        impl.notFullscreen(() => {
          impl.width(this._widthBeforeFullscreen);
          impl.height(this._heightBeforeFullscreen);
        });
      }
    }
  }

  inFullscreen() {
    return !!this._inFullscreen;
  }

  inNativeFullscreen() {
    return !!this._nativeFullscreen;
  }

  maybeAutoFullscreen = () => {
    if (this.impl.isAudio()) {
      return;
    }
    const shouldAutoFullscreen =
      this.impl._inNativeMode() &&
      this.impl._opts.playsinline === false &&
      inUserEventContext() &&
      !this._hasAutoFullscreened;
    if (shouldAutoFullscreen) {
      this._hasAutoFullscreened = true;
      this.requestFullscreen();
    }
  };
}

FullscreenBehavior.handle = 'fullscreen';

if (!W._onFullscreenChange) {
  W._onFullscreenChange = () => {
    if (fullscreenElement()) {
      globalTrigger('enterfullscreen', fullscreenElement());
    } else {
      globalTrigger('cancelfullscreen');
    }
  };

  W._initializers.initFullscreenTriggers = () => {
    // The fullscreenchange event is a global thing, not for a specific
    // element.  This sets up one binding that marshalls the fullscreenchange
    // event so we can receive it like Wistia.bind 'fullscreenchange',
    // (theElem) -> ...
    //
    // If embed codes use it, they should examine theElem to figure out if
    // it's actually the embed going fullscreen, and not something else on
    // the page.
    elemRebind(document, 'mozfullscreenchange', W._onFullscreenChange);
    elemRebind(document, 'webkitfullscreenchange', W._onFullscreenChange);
    elemRebind(document, 'MSFullscreenChange', W._onFullscreenChange);
    elemRebind(document, 'fullscreenchange', W._onFullscreenChange);
  };

  W._destructors.destroyFullscreenTriggers = () => {
    elemUnbind(document, 'mozfullscreenchange', W._onFullscreenChange);
    elemUnbind(document, 'webkitfullscreenchange', W._onFullscreenChange);
    elemUnbind(document, 'MSFullscreenChange', W._onFullscreenChange);
    elemUnbind(document, 'fullscreenchange', W._onFullscreenChange);
  };
}

export default FullscreenBehavior;
