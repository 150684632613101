import type { PublicApi, WistiaContainerHTMLElement } from 'src/types/e-v1-player-api-types.d.ts';
import { poll } from './poll.js';
import { getOneApiHandle } from './getApiHandles.ts';
import { getAllApiEmbedElements } from './getEmbedElements.ts';
import { elemHasClass, elemMutationObserver } from './elem.js';
import { isDocReady, onDocReady } from './docReady.js';
import { wlog } from './wlog.js';

type MatcherFunction = (
  video?: MatcherFunction | PublicApi | WistiaContainerHTMLElement | 'removed' | null,
) => void;
type MatcherEventCallback = (event: CustomEvent) => void;
type CleanupCallback = () => void;

// Event names
const INIT_EMBED_EVENT = 'initembed';
const AFTER_REPLACE_EVENT = 'afterreplace';

// The special key which matches all api handles
export const ALL_API_HANDLES_KEY = '_all';

// The mutation observer which watches for new wistia-player elements
// Should only be defined once
let WistiaPlayerElementAddedObserver = null as MutationObserver | null;

// The list of functions which should run when a new api handle is found
const onFindApiFunctionsList = [] as MatcherFunction[];
const onFindApiEventCallbacksList = [] as MatcherEventCallback[];

/**
 * Run a function on either legacy or web component embeds
 * @param {WistiaContainerHTMLElement} wistiaPlayer - Embed element containing the api handle
 * @param {Function} fn - Function to run
 * @returns {void}
 */
const runFunctionOnAnyEmbedType = (
  wistiaPlayer: WistiaContainerHTMLElement,
  fn: MatcherFunction,
) => {
  // TODO: We need to call the PublicApi methods for now, but once WistiaPlayer
  // is more built out we should be able to just send that over directly
  const api = wistiaPlayer.wistiaApi ?? wistiaPlayer.api ?? null;
  if (api !== null && api !== 'removed') {
    fn(api);
  }
};

/**
 * Set and return the event listeners for a wistia-player custom element
 * @param {WistiaContainerHTMLElement} wistiaPlayer - Embed element
 * @param {Function} runIfMatchCallback - Function to try running when event is fired
 * @returns {Function[]} - RemoveEventListener functions
 */
const setAndReturnEventListeners = (
  wistiaPlayer: WistiaContainerHTMLElement,
  runIfMatchCallback: MatcherEventCallback,
): CleanupCallback[] => {
  wistiaPlayer.addEventListener(INIT_EMBED_EVENT, runIfMatchCallback);
  wistiaPlayer.addEventListener(AFTER_REPLACE_EVENT, runIfMatchCallback);

  return [
    () => wistiaPlayer.removeEventListener(INIT_EMBED_EVENT, runIfMatchCallback),
    () => wistiaPlayer.removeEventListener(AFTER_REPLACE_EVENT, runIfMatchCallback),
  ] as CleanupCallback[];
};

/**
 * Set up a mutation observer to watch for new embed elements
 * Should only be called and created once
 * @returns {void}
 */
const createNewEmbedMutationObserver = () => {
  WistiaPlayerElementAddedObserver = elemMutationObserver((mutations: MutationRecord[]) => {
    mutations.forEach((mutation) => {
      const { addedNodes } = mutation as unknown as MutationRecord;
      addedNodes.forEach((addedNode: HTMLElement) => {
        if (addedNode.tagName === 'WISTIA-PLAYER' || elemHasClass(addedNode, 'wistia_embed')) {
          const wistiaPlayer = addedNode as WistiaContainerHTMLElement;
          // Run any functions which should run when a new embed is found
          onFindApiFunctionsList.forEach((onFindApiFunction) => {
            runFunctionOnAnyEmbedType(wistiaPlayer, onFindApiFunction);
          });

          // And set up event listeners for that embed
          onFindApiEventCallbacksList.forEach((onFindApiEventCallback) => {
            setAndReturnEventListeners(wistiaPlayer, onFindApiEventCallback);
          });
        }
      });
    });
  }) as MutationObserver;

  onDocReady(() => {
    if (WistiaPlayerElementAddedObserver !== null) {
      try {
        WistiaPlayerElementAddedObserver.observe(document.body, { subtree: true, childList: true });
      } catch (err: unknown) {
        wlog.error(err);
      }
    }
  });
};

/**
 * Run a function and set up listeners on any embeds which already exist in the dom
 * @param {Function} runIfMatch - Function to try running
 * @param {Function} runIfMatchCallback - Callback to set on event listeners
 * @returns {Function[]} - Cleanup functions (unbind or removeEventListener)
 */
const setupOnFindForExistingEmbeds = (
  runIfMatch: MatcherFunction,
  runIfMatchCallback: MatcherEventCallback,
) => {
  const legacyEmbeds = Array.from(getAllApiEmbedElements());
  const webComponentEmbeds = Array.from(
    document.getElementsByTagName('wistia-player'),
  ) as WistiaContainerHTMLElement[];

  const wistiaPlayers = legacyEmbeds.concat(webComponentEmbeds);

  return wistiaPlayers.reduce(
    (accumulator: CleanupCallback[], wistiaPlayer: WistiaContainerHTMLElement) => {
      // Try running the function on every existing api handle now
      runFunctionOnAnyEmbedType(wistiaPlayer, runIfMatch);

      // Set up event listeners for this embed's initembed and afterreplace events
      return [...accumulator, ...setAndReturnEventListeners(wistiaPlayer, runIfMatchCallback)];
    },
    [],
  );
};

/**
 * Run a function on a single API handle or all API handles when they are found
 * @param {HTMLElement | number | string} matcher - The matcher
 * @param {Function} fn - The function to run
 * @returns {Function} - The function to unbind the event listener
 */
export const onFindApiHandle = (
  matcher: HTMLElement | number | string,
  fn: MatcherFunction,
): void => {
  // If the matcher is the special key '_all', run the function on any and all api handles
  // otherwise run the function on the single api handle which matches the matcher
  const runIfMatch = ((video) => {
    if (matcher === ALL_API_HANDLES_KEY || getOneApiHandle(matcher) === video) {
      fn(video);
      return true;
    }
    return false;
  }) as MatcherFunction;

  // Save the event callback separately so we avoid adding duplicate event listeners
  // when an element is removed and added back to the dom
  const runIfMatchCallback = (event: CustomEvent<WistiaContainerHTMLElement>) => {
    // event.detail.api contains an instance of this embed's api
    const { api } = event.detail;
    if (api !== 'removed') {
      fn(api);
    }
  };

  // Add this function to the list of functions which run and are bound to the
  // initembed and afterreplace events when NEW embeds are added to the dom
  onFindApiFunctionsList.push(runIfMatch);
  onFindApiEventCallbacksList.push(runIfMatchCallback);

  // Initialize the single mutation observer which watches for new embed elements
  if (WistiaPlayerElementAddedObserver === null) {
    createNewEmbedMutationObserver();
  }

  // Run the function on any existing embeds and add listeners for initembed and afterreplace events
  const runOnExistingEmbeds = () => {
    const cleanupCallbacks = setupOnFindForExistingEmbeds(runIfMatch, runIfMatchCallback);

    return () => cleanupCallbacks.map((cleanupCallback) => cleanupCallback());
  };

  const POLL_INTERVAL = 300;
  const POLL_TIMEOUT = 10000;

  // Try it once immediately, then poll until the document is ready
  runOnExistingEmbeds();
  poll(isDocReady, runOnExistingEmbeds, POLL_INTERVAL, POLL_TIMEOUT);
};
