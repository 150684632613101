import type {
  EmbedOptions,
  Logger as LoggerType,
  PublicApi,
  MediaData,
  PublicApiOptions,
  PlayerState,
} from '../types/e-v1-player-api-types.d.ts';
import type { PlayerColorChangeEventData } from '../types/custom-event-data.js';
import type { DynamicImportOptions } from '../utilities/dynamicImport.ts';
import { elemWidth } from '../utilities/elem.js';
import { sanePlayerColor } from '../utilities/sane-player-color.js';
import { wlog } from '../utilities/wlog.js';
import { dynamicImport } from '../utilities/dynamicImport.ts';

// The component will not run without these attributes.
const requiredAttributes = ['media-id'];

// Optional attributes surfaced in documentation to our customers.
const optionalPublicAttributes = [
  'aspect',
  'autoplay',
  'big-play-button',
  'disable-fullscreen-on-rotate-to-landscape',
  'do-not-track',
  'muted',
  'player-color',
];

// Optional attributes used by Wistia developers.
const optionalPrivateAttributes = ['embed-host', 'use-web-component'];

const defaultEmbedOptions = {
  autoplay: false,
  bigPlayButton: true,
  playerColor: '636155',
  state: 'beforeplay' as PlayerState,
};

export class WistiaPlayer extends HTMLElement {
  public plugin: object | null;

  private readonly _logger: LoggerType;

  // can be null when doing document.createElement and referencing an attribute/property
  // before being injected into the dom
  #_api: PublicApi | null;

  #hasElementConnectedToDOM = false;

  #mediaDataServerJson: MediaData = {};

  /**
   * Represents one embedded Wistia media player.
   * @constructor
   */
  public constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    // Set up a prefixed logger
    this._logger = (wlog as unknown as LoggerType).getPrefixedFunctions(
      'WistiaPlayer',
    ) as LoggerType;
  }

  // --------------------------------------------------
  // Public properties
  // --------------------------------------------------

  /**
   * Return an array of the attributes that we want to observe for changes.
   * If one of these attributes changes, the attributeChangedCallback will be called.
   * @returns {string[]}
   */
  public static get observedAttributes(): string[] {
    return [...requiredAttributes, ...optionalPublicAttributes, ...optionalPrivateAttributes];
  }

  /**
   * Returns the public api instance.
   * TODO: Not sure if we want to expose this.
   * @returns {PublicApi | null}
   */
  public get api(): PublicApi | null {
    return this.#_api;
  }

  /**
   * Returns the aspect ratio (width / height) of the originally uploaded video or given aspect ratio.
   * @returns {number}
   * @see https://wistia.com/support/developers/player-api#aspect
   */
  public get aspect(): number {
    // Attributes set on wistia-player override all other options
    if (this.hasAttribute('aspect')) {
      return Number(this.getAttribute('aspect'));
    }

    // If no attribute is set, get the value straight from the api, or fallback to the default.
    return this.api?.aspect() ?? this.offsetWidth / this.offsetHeight;
  }

  /**
   * Sets the aspect ratio (width / height) of the video.
   * @returns {void}
   */
  public set aspect(newAspect: number) {
    this.setAttribute('aspect', newAspect.toString());
    if (this.api) {
      this.api._attrs.aspect = newAspect;
      // By re-setting width to the same value, we trigger the aspect ratio to be recalculated.
      this.api.width(elemWidth(this) as number, { constrain: true });
    }
  }

  /**
   * Returns if the player should attempt to autoplay as soon as it's ready.
   * @returns {boolean}
   * @see https://wistia.com/support/developers/embed-options#autoplay
   */
  public get autoplay(): boolean {
    return this.#isAttributePresentOrTrue('autoplay');
  }

  /**
   * Sets the attribute to enable/disable autoplay.
   * @param {boolean} shouldSetAutoplay
   * @returns {void}
   * @see https://wistia.com/support/developers/embed-options#autoplay
   */
  public set autoplay(shouldSetAutoplay: boolean) {
    if (shouldSetAutoplay) {
      this.setAttribute('autoplay', '');
    } else {
      this.removeAttribute('autoplay');
    }
  }

  /**
   * If set to true, the big play button control will appear in the center of the video before play.
   * @returns {boolean}
   * @see https://wistia.com/support/developers/embed-options#playbutton
   */
  public get bigPlayButton(): boolean {
    // Attributes set on wistia-player override all other options
    if (this.hasAttribute('big-play-button')) {
      return this.getAttribute('big-play-button') !== 'false';
    }

    // If no attribute is set, get the value straight from the api, or fallback to the default.
    return this.api?._attrs.playButton ?? defaultEmbedOptions.bigPlayButton;
  }

  /**
   * If set to true, the big play button control will appear in the center of the video before play.
   * @param {boolean} shouldDisplay
   * @returns {void}
   * @see https://wistia.com/support/developers/embed-options#playbutton
   */
  public set bigPlayButton(shouldDisplay: boolean) {
    this.setAttribute('big-play-button', shouldDisplay.toString());
    this.api?.bigPlayButtonEnabled(shouldDisplay);
  }

  /**
   * Returns the current time of the video as a decimal in seconds.
   * @returns {number}
   * @see https://wistia.com/support/developers/player-api#time
   */
  public get currentTime(): number {
    return this.api?.time() ?? 0;
  }

  /**
   * Sets the current time of the video as a decimal in seconds.
   * @param {number} shouldSetAutoplay
   * @returns {void}
   * @see https://wistia.com/support/developers/player-api#time
   */
  public set currentTime(newTime: number) {
    this.#_api?.time(newTime);
  }

  /**
   * If set to true, the video will not automatically go to true fullscreen on a mobile device.
   * The player will rotate, and the viewer can still click on the fullscreen option after rotating.
   * @returns {boolean}
   */
  public get disableFullscreenOnRotateToLandscape(): boolean {
    return this.#isAttributePresentOrTrue('disable-fullscreen-on-rotate-to-landscape');
  }

  /**
   * If set to true, the video will not automatically go to true fullscreen on a mobile device.
   * The player will rotate, and the viewer can still click on the fullscreen option after rotating.
   * @param {boolean} shouldDisable
   * @returns {void}
   */
  public set disableFullscreenOnRotateToLandscape(shouldDisable: boolean) {
    this.setAttribute('disable-fullscreen-on-rotate-to-landscape', shouldDisable.toString());

    if (this.api) {
      this.api._attrs.fullscreenOnRotateToLandscape = !shouldDisable;
    }
  }

  /**
   * return the status of the do not track embed option that controls whether the player
   * sends tracking pings.
   * @returns {boolean}
   */
  public get doNotTrack(): boolean {
    return this.#isAttributePresentOrTrue('do-not-track');
  }

  /**
   * When present the player will not send tracking events for stats.
   * Note that this must be set at the time of embed to have any impact
   */
  public set doNotTrack(dontTrack: boolean) {
    this.setAttribute('do-not-track', dontTrack.toString());

    if (this.api) {
      this.api._attrs.doNotTrack = dontTrack;
    }
  }

  /**
   * Returns the overridding embed host for the player.
   * Internal use only.
   * @returns {string}
   */
  public get embedHost(): string {
    return this.getAttribute('embed-host') ?? '';
  }

  /**
   * Sets the overridding embed host for the player.
   * Internal use only.
   * @param {string} newEmbedHost
   * @returns {void}
   */
  public set embedHost(newEmbedHost: string) {
    this.setAttribute('embed-host', newEmbedHost);
  }

  /**
   * Returns the hashed id of the media.
   * @returns {string}
   */
  public get mediaId(): string {
    return this.getAttribute('media-id') ?? '';
  }

  /**
   * Sets the hashed id of the media.
   * @param {string} newMediaId
   * @returns {void}
   */
  public set mediaId(newMediaId: string) {
    this.setAttribute('media-id', newMediaId);
    // TODO: Replace media when mediaId changes
  }

  /**
   * Returns if player is currently muted
   * @returns {boolean}
   * @see https://wistia.com/support/developers/embed-options#muted
   */
  public get muted(): boolean {
    return Boolean(this.#_api?._impl.isMuted());
  }

  /**
   * Change player muted state
   * @param {boolean} val
   * @see https://wistia.com/support/developers/embed-options#muted
   */
  public set muted(val: boolean) {
    if (val) {
      void this.#_api?._impl.mute();
      this.setAttribute('muted', '');
    } else {
      void this.#_api?._impl.unmute();
      this.removeAttribute('muted');
    }
  }

  /**
   * Returns the base color of the player.
   * @returns {string}
   * @see https://wistia.com/support/developers/embed-options#playercolor
   */
  public get playerColor(): string {
    return this.getAttribute('player-color') ?? defaultEmbedOptions.playerColor;
  }

  /**
   * Changes the base color of the player.
   * Expects a hexadecimal rgb string like “ff0000” (red), “000000” (black), “ffffff” (white), or “0000ff” (blue).
   * @param {string} newColor
   * @returns {void}
   * @see https://wistia.com/support/developers/embed-options#playercolor
   */
  public set playerColor(newColor: string) {
    this._logger.info('set playerColor', newColor);
    const prevPlayerColor = this.playerColor;
    const finalHexColor = sanePlayerColor(newColor) as string;
    this.setAttribute('player-color', finalHexColor);

    if (prevPlayerColor !== finalHexColor) {
      this.dispatchEvent(
        new CustomEvent<PlayerColorChangeEventData>('playercolorchange', {
          detail: { color: finalHexColor, prevColor: prevPlayerColor },
        }),
      );
    }
  }

  /**
   * Returns the current state of the video.
   * @returns {PlayerState}
   * @see https://wistia.com/support/developers/player-api#state
   */
  public get state(): PlayerState {
    return this.api?.state() ?? defaultEmbedOptions.state;
  }

  /**
   * @returns {boolean} the value for the `use-web-component` attribute or property
   */
  public get useWebComponent(): boolean {
    const val = this.getAttribute('use-web-component');

    return val === 'true';
  }

  /**
   * @param {boolean} val Value for the `use-web-component` attribute or property
   */
  public set useWebComponent(val: boolean) {
    if (val) {
      this.setAttribute('use-web-component', String(val));
    } else {
      this.removeAttribute('use-web-component');
    }
  }

  /**
   * @returns {MediaData} object containing the final mediaData object that will be given to the PublicApi
   */
  get #mediaDataConfig(): MediaData {
    const result = { ...this.#mediaDataServerJson };

    const embedOptionsFromAttributes = { ...this.#getEmbedOptionsFromAttributes() };
    // we want to strip any null/undefined embed options so they don't override
    // anything from the mediaData response :/
    const cleanEmbedOptions = Object.fromEntries(
      Object.entries(embedOptionsFromAttributes).filter(([_k, val]) => !(val === null)),
    );

    result.embedOptions = {
      ...result.embedOptions,
      ...cleanEmbedOptions,
    };

    return result;
  }

  // --------------------------------------------------
  // Public api methods
  // --------------------------------------------------

  /**
   * Attempts to load the media data from the server.
   * Public so it can be mocked in tests.
   * @param {number | string} mediaId
   * @param {DynamicImportOptions} options
   * @returns {void}
   */
  public async importMediaData(
    mediaId: number | string,
    options: DynamicImportOptions,
  ): Promise<unknown> {
    return dynamicImport(`embed/${String(mediaId)}.js`, options);
  }

  /**
   * Pauses the video.
   * If this is called and the video’s state is “playing,”
   * it’s expected that it will change to “paused.”
   * @returns {Promise<void>}
   */
  public async pause(): Promise<void> {
    return this.api?._impl.pause();
  }

  /**
   * Plays the video.
   * If this is called, it is expected that the state will change to “playing.”
   * @returns {Promise<void>}
   */
  public async play(): Promise<void> {
    return this.api?._impl.play();
  }

  /**
   * Attempt to enter fullscreen mode.
   * @returns {Promise<void>}
   * @see https://wistia.com/support/developers/player-api#requestfullscreen
   */
  public async requestFullscreen(): Promise<void> {
    // TODO: Custom element handles its own requestFullscreen method
    // TODO: This will return a promise with the new translation layer - using this wrapper for now
    return this.#promiseApiWrapper(this.api?.requestFullscreen as unknown as () => PublicApi);
  }

  // --------------------------------------------------
  // Custom element lifecycle methods
  // --------------------------------------------------

  /**
   * Called when an observed attribute has been added, removed, updated, or replaced.
   * Also called for initial values when an element is created by the parser, or upgraded.
   * Note: only attributes listed in the observedAttributes property will receive this callback.
   * @param {string} name - The name of the attribute that changed.
   * @param {string} oldValue - The previous value of the attribute, or null if it was added for the first time.
   * @param {string} newValue - The new value of the attribute, or null if it was removed.
   * @returns {void}
   */
  protected attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    // We need this to make sure we don't needlessly call api methods during initial component setup
    if (!this.#hasElementConnectedToDOM) {
      return;
    }

    if (oldValue === newValue) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (oldValue === null && newValue === '') {
      return;
    }

    // Attribute names must match their corresponding property names (kebab-case -> camelCase)
    // So the player-color attribute maps to the playerColor property
    this[this.#kebabToCamelCase(name)] = newValue;
  }

  protected connectedCallback(): void {
    const mediaId = this.getAttribute('media-id');
    if (mediaId == null) {
      throw new Error('media-id attribute is required');
    }

    // if we're coming from a legacy E-v1.js embed, it is likely to already have JSONP on the page.
    // use the old init flow.
    if (this.useWebComponent) {
      const embedOptions = this.#getEmbedOptionsFromAttributes();

      // Create and save the public api instance
      this.#initPublicApi(mediaId, embedOptions);
    } else {
      const options: DynamicImportOptions = {};

      if (this.#getValueFromAttribute('embed-host') !== null) {
        options.host = this.embedHost;
      }

      this.importMediaData(mediaId, options)
        .then((module: PublicApiOptions) => {
          const { mediaData } = module;
          this.#mediaDataServerJson = mediaData;

          // Create and save the public api instance
          this.#initPublicApi(mediaId, {
            mediaData: this.#mediaDataConfig,
          });
        })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    }

    // Add our responsive embed template to the shadow DOM
    if (this.shadowRoot) {
      this.shadowRoot.appendChild(this.#createEmbedTemplate().content.cloneNode(true));
    }

    this.#hasElementConnectedToDOM = true;
  }

  // --------------------------------------------------
  // Private methods
  // --------------------------------------------------

  /**
   * Creates a template for the embed code
   * @returns {HTMLTemplateElement}
   */
  #createEmbedTemplate(): HTMLTemplateElement {
    const template = document.createElement('template');

    // web components always default to display: inline, which we don't want.
    template.innerHTML = /* html */ `
      <style>
        :host {
          display: block;
        }
      </style>
  `;

    return template;
  }

  /**
   * Gets the embed options from the element's attributes
   * @returns {Record<string, boolean | number | object | string | null>}
   */
  #getEmbedOptionsFromAttributes(): Record<string, boolean | number | object | string | null> {
    return {
      aspect: this.#getValueFromAttribute('aspect'),
      autoPlay: this.#getValueFromAttribute('autoplay'), // Legacy embed option has a capital P
      doNotTrack: this.#getValueFromAttribute('do-not-track'),
      fullscreenOnRotateToLandscape: this.#getOverrideForFullscreenOnRotateToLandscape(), // Legacy embed option has the opposite meaning
      embedHost: this.#getValueFromAttribute('embed-host'),
      muted: this.#getValueFromAttribute('muted'),
      playButton: this.#getValueFromAttribute('big-play-button'), // Legacy embed option assumes 'playButton' means the big one
      playerColor: this.#getValueFromAttribute('player-color'),
      videoFoam: !(this.style.width || this.style.height),
    };
  }

  /**
   * Legacy fullscreenOnRotateToLandscape option has the opposite meaning of the new
   * disable-fullscreen-on-rotate-to-landscape option, so we need to invert the value
   * @returns {boolean | null}
   */
  #getOverrideForFullscreenOnRotateToLandscape(): boolean | null {
    const value = this.#getValueFromAttribute('disable-fullscreen-on-rotate-to-landscape');
    if (value === null) {
      return null;
    }

    return !(value !== 'false');
  }

  /**
   * Gets the value of an attribute if it exists, returns null if not
   * @param {string} name - Name of the attribute
   * @returns {boolean | string | null}
   */
  #getValueFromAttribute(name: string): boolean | string | null {
    if (this.hasAttribute(name)) {
      // Boolean attributes are set to '' when they are present
      // so we need to convert them to true
      return this.getAttribute(name) === '' ? true : this.getAttribute(name);
    }

    // If no attribute is present, return null instead of an explicit false
    // so our media data config doesn't get overridden
    return null;
  }

  /**
   * Initializes the public api instance and sends a ready event
   * @param {number | string} mediaId - The media id
   * @param {EmbedOptions | PublicApiOptions | undefined} options - The public api options
   * @returns {void}
   */
  #initPublicApi(
    mediaId: number | string,
    options: EmbedOptions | PublicApiOptions | undefined,
  ): void {
    const { Wistia } = window;
    if (!Wistia?.PublicApi) {
      throw new Error('Wistia.PublicApi is not defined');
    }

    this.#_api = new Wistia.PublicApi(mediaId, options) as PublicApi;
    this.dispatchEvent(new CustomEvent('ready', { detail: { mediaId, api: this.#_api } }));
  }

  /**
   * Checks if an attribute is present and is not set to false
   * @param {string} name - Name of the attribute
   * @returns {boolean}
   */
  #isAttributePresentOrTrue(name: string): boolean {
    return (
      this.#getValueFromAttribute(name) !== 'false' && this.#getValueFromAttribute(name) !== null
    );
  }

  /**
   * Takes a string in kebab-case and converts it to camelCase
   * This is a pretty generic helper function, so it could be moved to a utility file if needed
   * @param {string} kebabString - String in kebab-case
   * @returns {string}
   */
  #kebabToCamelCase(kebabString: string): string {
    return kebabString.replace(/-./g, (word) => word[1].toUpperCase());
  }

  /**
   * Wraps an existing non-promise-based api call in a promise
   * @param {Function} apiCall - The api call to wrap
   * @returns {Promise<void>}
   */
  #promiseApiWrapper = async (apiCall: () => PublicApi): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        apiCall.call(this.api, apiCall);
        resolve();
      } catch (error: unknown) {
        reject(error);
      }
    });
  };
}

if (customElements.get('wistia-player') === undefined) {
  customElements.define('wistia-player', WistiaPlayer);
}
