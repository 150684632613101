import W from 'wistia_namespace.js';

if (W._remoteData == null) {
  W._remoteData = {};
}

export const cacheMediaData = (hashedId, data) => {
  return (W._remoteData[`media_${hashedId}`] = data);
};

export const uncacheMediaData = (hashedId) => {
  W._remoteData[`media_${hashedId}`] = null;
  delete W._remoteData[`media_${hashedId}`];
};
